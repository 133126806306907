* {
	margin: 0;
}

html {
	height: 100%;
}

body, wrapper {
	min-height: 100vh;
}

main {
	flex: 1;
}

body {
	background-color: #fdfdfd;
	height: 100%;
	font-family: 'Roboto', sans-serif;
	font-size: 1.2rem;
}

header {
	margin-bottom: 40px;
}

nav {
	font-size: 1rem;
}

.navbar-dark .navbar-nav .dropdown-menu a:link, .navbar-dark .navbar-nav .dropdown-menu a:visited, .navbar-dark .navbar-nav .dropdown-menu a:hover, .navbar-dark .navbar-nav .dropdown-menu a:active {
	color: #007bff;
}

img {
	max-width: 100%;
}

#user-name {
	font-size: 4rem;
}

.user-description {
	padding: 15px;
	font-size: initial;
}

.user-profile {
	padding: 0;
}

.user-picture {
	border-style: solid;
	border-width: 5px;
	border-color: #292929;
	margin-top: 20px;
}

i {
	margin: 10px 20px 10px 20px;
	color: #333;
}

.post-list .card {
	margin-bottom: 20px;
}

.post-list .card .card-link {
	font-size: 1rem;
}

.post-meta {
	font-size: .9rem;
	margin: -7px 0 20px;
}

.post-meta i {
	margin: 0 5px 0 0;
}

.post-header {
	background-color: #F5F5F5;
	overflow: hidden;
	padding: 0 5px 0 5px;
}

.post-summary, .post-content{
	clear: both;
}

.post-summary {
	padding: 0 5px 0 5px;
}

.post-list-footer {
	padding-bottom: 5px;
}

#post-list {
	list-style: none;
	padding-left: 0;
}

.post-list-item {
	margin-top: 20px;
	border-style: solid;
	border-width: 0 0 3px 0;
	border-color: #292929;
}

.post-comments {
	padding-top: 10px;
	margin-top: 40px;
}

.wrapper {
	min-height: 100%;
	margin: 0 auto -50px;
}

.footer {
	background-color: #F5F5F5;
	display: block;
	width: 100%;
	padding: 20px 10px 0;
	font-size: 1rem;
}

.col-centered {
	float: none;
	margin: 0 auto;
}

div p {
	text-align: justify;
}

td {
	margin: 0 20px 0 20px;
}

.user-picture {
	margin: 0 auto;
}

twitterwidget {
	margin-left: auto;
	margin-right: auto;
}

#search-results {
	margin-top: 40px;
}

#search-results ul {
	padding: 0;
}

.search-result-excerpt p {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 100%;
}

pre {
	border-top: 1px solid #ccc;
	border-bottom: 1px solid #ccc;
	padding: 10px 0;
}

.fa-external-link, .fa-link {
	margin-left: 5px;
	margin-right: 5px;
}

.related-content {
	padding: 10px;
	margin: 60px 0 20px;
	font-size: 1rem;

	h5 {
		font-size: 1rem;
	}

	ul {
		padding: 0 0 0 20px;
		margin-bottom: 0;

		li {
			margin: 0 0 10px -20px;
			padding: 0 0 10px 0;
			list-style-type: none;
			border-bottom: 1px solid rgba(0,0,0,.1);

			&:first-child {
				margin-top: 10px;
			}

			&:last-child {
				border-bottom: none;
				margin: 0 0 0 -20px;
				padding-bottom: 0;
			}
		}
	}
}

blockquote {
	border-left: 5px solid #5680A4;
	background: lighten(#5680A4, 40);
	padding: 5px 20px 5px 15px;
	display: inline-block;

	> p:last-child {
		margin-bottom: 0;
	}
}

.post-content .featured-images {
	margin-left: 20px!important;
}

.fa-rss {
	margin: 5px;
}

.emoji {
	font-family: Apple Color Emoji,Segoe UI Emoji,NotoColorEmoji,Segoe UI Symbol,Android Emoji,EmojiSymbols;
}

.pagination-wrapper {
	webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
}

@media only screen and (max-width: 600px) {
	.pagination-wrapper {
		webkit-box-flex: 0;
	    -ms-flex: 0 0 100%;
	    flex: 0 0 100%;
	    max-width: 100%;
	    margin-top: 20px;
	}

	.sidebar-nav {
		padding: 20px;
		border: 1px solid rgba(0,0,0,.125);

		a {
			display: inline-block;
			margin: 0 0 10px;
		}
	}

	header nav .btn-secondary, .btn-primary {
		width: 100%;
	}
}

.pagination {
	margin: 0 auto 20px;
	width: fit-content;
}

.covid19-table table {
	width: 100%;
	margin-bottom: 20px;

	th {
		text-align: center;
	}

	td {
		padding: 5px 0;
		border-bottom: 1px solid #000;
		text-align: center;
	}

	tr:hover {
		background: #eee;
	}

	tr:last-child {
		td {
			border-bottom: 0;
		}
	}
}

.number-delta {
	font-size: 85%;
}

.virus-data {
	width: 400px;
	font-size: 70%;
}

// .post-content table, .post-content th, .post-content td {
// 	border: 1px solid black;
// }

// .virus-data td {
// 	text-align: right;
// }
// .post-content th, .post-content td {
// 	text-align: center;
// 	padding: 5px;
// }
// .gist-file td {
//     border: none;
// }

